import React from 'react'
import { Layout } from '../components'
import { ResetPassword } from '../components/authForms'
import '../components/authForms/authForms.scss'

const Auth = () => {
  return (
    <Layout>
      <div className="section-login section-inner section-inner--xs vertical-indent--m">
        <h2>Восстановление пароля</h2>
        <ResetPassword />
      </div>
    </Layout>
  )
}

export default Auth
